.App {
  text-align: center;
}

.App h1 {
  font-size: 2.3rem;
  font-weight: 500;
  margin: 16px 0 8px;
}

.App hr {
  margin: 8px 0;
}

.App nav section {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  justify-content: center;
  margin: 8px 0;
}

.App a,
.App a:visited {
  color: blue;
  text-decoration: none;
}

.App a:hover {
  text-decoration: underline;
}

.App main {
  margin: 16px 0;
}
